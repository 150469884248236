import { useDecision } from '@optimizely/react-sdk';
import { useEffect } from 'react';
import { OPTIMIZELY_FRONTLINE_SIDEBAR } from '../../consts/optimizely';

function useFrontlineSidebarRedirect(path_base: string): void {
  const [frontlineSidebarFlag, frontlineSidebarFlagReady] = useDecision(
    OPTIMIZELY_FRONTLINE_SIDEBAR
  );
  const isFrontlineSidebarFlagEnabled = frontlineSidebarFlagReady && frontlineSidebarFlag.enabled;
  const isFrontlineRoute = location.pathname.startsWith('/frontline');

  useEffect(() => {
    if (frontlineSidebarFlagReady) {
      if (isFrontlineSidebarFlagEnabled) {
        localStorage.setItem('isFrontline', 'true');

        if (isFrontlineRoute || path_base === '/frontline') {
          const isInForce = location.pathname.startsWith('/force');
          if (isInForce && path_base === '/frontline') {
            location.reload();
          } else {
            location.href = location.href.replace('/frontline', '/force/frontline');
          }
        }
      } else {
        localStorage.removeItem('isFrontline');
        const isInForce = location.pathname.startsWith('/force');
        if (isInForce || path_base === '/force/frontline') {
          location.href = location.href.replace('/force/frontline', '/frontline');
        }
      }
    }
  }, [frontlineSidebarFlagReady, isFrontlineRoute, isFrontlineSidebarFlagEnabled, path_base]);
}

export { useFrontlineSidebarRedirect };
