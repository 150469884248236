import { Paragraph, SkeletonLoader, Tooltip } from '@hexa-ui/components';
import { ChevronDown, Edit2, Info } from '@hexa-ui/icons';
import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import ConfigureOperationModal from '../ConfigureOperationModal/ConfigureOperationModal';
import {
  ContainerOperationsButton,
  InfoIconContainer,
  OperationButton,
  OperationContainer,
  OperationFrame,
  OperationLabel,
} from './OperationSelect.styles';
import { useOperationSelect } from './hooks/useOperationSelect';

interface OperationSelectProps {
  country?: string;
  organization: string;
  seller?: string;
  disabled?: boolean;
  labelId: string;
  profileView?: boolean;
  hasInformationIcon?: boolean;
  width?: CSSProperties['width'];
}

const OperationSelect = ({
  organization,
  seller,
  country,
  disabled,
  labelId,
  profileView,
  hasInformationIcon,
  width = 'max-content',
}: OperationSelectProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { operationLabel, handleOpenModal, handleCloseModal, modalOpened, isLoading } =
    useOperationSelect({
      organization,
      seller,
      country,
      disabled,
    });

  return (
    <OperationFrame>
      <OperationLabel>{labelId}</OperationLabel>
      <OperationContainer data-testid="operation-container" disabled={disabled} width={width}>
        {!isLoading ? (
          <ContainerOperationsButton>
            <OperationButton
              data-testid="operation-select-button"
              type="button"
              disabled={disabled}
              onClick={handleOpenModal}
            >
              <Paragraph weight="medium">{operationLabel}</Paragraph>
              {profileView ? <ChevronDown size="medium" /> : <Edit2 size="medium" />}
            </OperationButton>
            {hasInformationIcon && (
              <InfoIconContainer data-testid="operation-select-info">
                <Tooltip
                  placement="left"
                  text={formatMessage({ id: 'formField.label.task.list.selectOperationTooltip' })}
                >
                  <Info size="large" />
                </Tooltip>
              </InfoIconContainer>
            )}
          </ContainerOperationsButton>
        ) : (
          <SkeletonLoader height="41.6px" />
        )}
      </OperationContainer>
      <ConfigureOperationModal
        isOpen={modalOpened}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
      />
    </OperationFrame>
  );
};

export default OperationSelect;
