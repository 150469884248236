import { useMemo, useState } from 'react';

export interface UseOperationSelectProps {
  country?: string;
  organization: string;
  seller?: string;
  disabled: boolean;
}

interface UseOperationSelectState {
  operationLabel: string;
  isLoading: boolean;
  modalOpened: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
}

export function useOperationSelect({
  disabled,
  seller,
  organization,
  country,
}: UseOperationSelectProps): UseOperationSelectState {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const handleOpenModal = () => {
    if (!disabled) {
      setModalOpened(true);
    }
  };

  const operationLabel = useMemo(() => {
    return country ? `${country} / ${organization}${seller?.length ? ` / ${seller}` : ''}` : '';
  }, [country, organization, seller]);

  const isLoading = !country;

  return {
    operationLabel,
    isLoading,
    modalOpened,
    handleOpenModal,
    handleCloseModal: () => setModalOpened(false),
  };
}
