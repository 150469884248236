import { Suspense, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PATH_BASE, PATH_TASK_GROUPS, PATH_TASK_LIST } from '../consts/route';
import { useFrontlineSidebarRedirect } from '../hooks/useFrontlineSidebarRedirect/useFrontlineSidebarRedirect';
import { usePermissions } from '../hooks/usePermissions/usePermissions';
import Page404 from '../pages/Page404';
import PageError from '../pages/PageError';
import PageLoading from '../pages/PageLoading';
import routesTaskGroups from './../apps/task-groups/routes';
import routesTaskManagement from './../routes';

const PageFallback = <PageLoading />;

const Router = (): JSX.Element => {
  const [pageAccessError, setPageAccessError] = useState(false);

  const canAccessTaskManagement = usePermissions('access-task-management', 'OR');
  const canAccessTaskGroups = usePermissions('access-task-groups', 'OR');
  const { formatMessage } = useIntl();
  useFrontlineSidebarRedirect(PATH_BASE);

  const isAccessTaskManagementRoute = location.pathname.startsWith(PATH_TASK_LIST);
  const isAccessTaskGroupsRoute = location.pathname.startsWith(PATH_TASK_GROUPS);

  const errorMessage = {
    message: formatMessage({ id: 'message.error.userWithoutGroups' }),
  };

  useEffect(() => {
    if (!canAccessTaskManagement && isAccessTaskManagementRoute) {
      setPageAccessError(true);
    }
    if (!canAccessTaskGroups && isAccessTaskGroupsRoute) {
      setPageAccessError(true);
    }
  }, [
    canAccessTaskGroups,
    canAccessTaskManagement,
    isAccessTaskGroupsRoute,
    isAccessTaskManagementRoute,
  ]);

  return (
    <BrowserRouter>
      <Routes>
        {canAccessTaskManagement &&
          routesTaskManagement.map((props) => <Route key={props.path} {...props} />)}

        {canAccessTaskGroups &&
          routesTaskGroups.map((props) => <Route key={props.path} {...props} />)}

        <Route
          path="*"
          element={
            <Suspense fallback={PageFallback}>
              {pageAccessError ? (
                <PageError error={errorMessage} onRetry={() => window.location.reload()} />
              ) : (
                <Page404 />
              )}
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
