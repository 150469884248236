import { Checkbox } from '@hexa-ui/components';
import { useDecision } from '@optimizely/react-sdk';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DocumentTitle, TypeToast, useAppHeader, useToast } from 'admin-portal-shared-services';
import { AxiosError, AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import OperationSelect from '../../../../components/OperationSelect';
import { OPTIMIZELY_KEY_FRONTEND_LOCAL_MODEL } from '../../../../consts/optimizely';
import { PATH_HOME } from '../../../../consts/route';
import useAppSelector from '../../../../hooks/useAppSelector';
import useGroupData from '../../../../hooks/useContext/useGroupData/useGroupData';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import { UserState } from '../../../../models/user';
import PageLoading from '../../../../pages/PageLoading';
import { preventFormSubmitOnEnter } from '../../../../utils/form';
import { getTaskGroup, patchTaskGroup } from '../../api/services/taskGroup';
import FooterTaskGroupForm from '../../components/FooterTaskGroupForm';
import { QUERY_KEY_TASK_GROUP_CARD } from '../../consts/query';
import { PATH_BASE, PATH_CREATE } from '../../consts/route';
import {
  FormTypeId,
  GetTaskGroupListResponse,
  PayloadUpdateTaskGroup,
  TaskGroupFormField,
  UpdateTaskGroup,
} from '../../models/task-group';
import { taskGroupSchema } from '../../schemas/taskGroup';
import {
  CardTaskGroupForm,
  ContainerSelect,
  ContainerTaskGroupsCreate,
  FiltersContainer,
  FormTaskGroupsCreate,
  InputProfileDisabled,
  InputTaskGroupMaxTasks,
  InputTaskGroupName,
  TextAreaTaskGroupDescription,
  WrapperInputMaxTasks,
  WrapperLocalModelCheckBox,
} from './TaskGroupUpdate.styles';

const TaskGroupUpdate = (): JSX.Element => {
  const { id: taskGroupId } = useParams();
  const { formatMessage } = useIntl();
  const [, setAppHeaderConfig] = useAppHeader();
  const toastService = useToast();
  const navigate = useNavigate();
  const { availableProfiles } = useAppSelector<UserState>((state) => state.user);
  const [selectedProfile, setSelectedProfile] = useState<string>();
  const isAdminUser = usePermissions('task-groups-admin');
  const isViewOnly = !isAdminUser;

  const { groupData, countrySelected } = useGroupData();
  const { vendorGroupId, organization, seller } = groupData;

  const [isFrontendLocalModelFlag, isFrontendLocalModelReady] = useDecision(
    OPTIMIZELY_KEY_FRONTEND_LOCAL_MODEL
  );
  const isLocalModelFlagEnabled = isFrontendLocalModelReady && isFrontendLocalModelFlag.enabled;

  const {
    isLoading: isLoadingTaskGroup,
    isFetching: isFetchingTaskGroup,
    isSuccess: isSuccessTaskGroup,
    data: taskGroup,
  } = useQuery<AxiosResponse<GetTaskGroupListResponse>, AxiosError>(
    [QUERY_KEY_TASK_GROUP_CARD, taskGroupId],
    ({ signal }) => getTaskGroup({ taskGroupId, vendorGroupId, signal }),
    {
      onError: () => {
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: 'app.taskGroups.toast.taskGroupError' }),
        });
        console.error('error');
        navigate(PATH_BASE);
      },
      staleTime: 0,
    }
  );

  const { isLoading: isLoadingCreateTaskGroup, mutate: mutatePatchTaskGroup } = useMutation(
    ({ body, taskGroupId, vendorGroupId }: UpdateTaskGroup) =>
      patchTaskGroup({ body, taskGroupId, vendorGroupId }),
    {
      onError: () => {
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: 'app.taskGroups.toast.error.update' }),
        });
      },
      onSuccess: () => {
        toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({ id: 'app.taskGroups.toast.success.update' }),
        });
        navigate(PATH_BASE + `?profile=${taskGroup.data.profileId}`);
      },
    }
  );

  useEffect(() => {
    if (isSuccessTaskGroup) {
      setSelectedProfile(
        availableProfiles.find((profile) => profile.id === taskGroup.data.profileId).title
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessTaskGroup]);

  const handleSubmitTask = (payload: PayloadUpdateTaskGroup) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { profileId, ...restPayload } = payload;
    const formattedPayload = restPayload;
    delete restPayload.isLocalModel;
    mutatePatchTaskGroup({ body: formattedPayload, taskGroupId, vendorGroupId });
  };

  const handleSubmitTaskGroup = () => {
    handleSubmitTask(formik.values as PayloadUpdateTaskGroup);
  };

  type FormValues = Partial<PayloadUpdateTaskGroup>;

  const formik = useFormik<FormValues>({
    initialValues: isSuccessTaskGroup
      ? {
          [TaskGroupFormField.Profile]: taskGroup.data.profileId,
          [TaskGroupFormField.Name]: taskGroup.data.name,
          [TaskGroupFormField.Description]: taskGroup.data.description,
          [TaskGroupFormField.MaximumTasks]: taskGroup.data?.maxTasksPerVisit,
          [TaskGroupFormField.IsLocalModel]: taskGroup.data.isLocalModel,
        }
      : {},
    validationSchema: taskGroupSchema,
    onSubmit: handleSubmitTaskGroup,
    enableReinitialize: true,
  });

  const hasError = (field: string) => !!formik.touched[field] && !!formik.errors[field];

  const pageTitle = isViewOnly
    ? formatMessage({ id: 'app.taskGroups.title.page.taskGroupView' })
    : formatMessage({ id: 'app.taskGroups.title.page.taskGroupUpdate' });

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle,
      breadcrumbConfig: {
        homePath: PATH_HOME,
        items: [
          {
            isCurrentPage: false,
            path: PATH_BASE,
            label: formatMessage({ id: 'app.taskGroups.title.page.taskGroupsList' }),
          },
          {
            isCurrentPage: true,
            path: PATH_CREATE,
            label: pageTitle,
          },
        ],
      },
    });
  }, [setAppHeaderConfig, formatMessage, pageTitle]);

  const handleChangeMaxNumbers = (event) => {
    const { value } = event.target;
    const intValue = parseInt(value, 10);

    if (isNaN(intValue) || intValue < 1) {
      formik.setFieldValue(TaskGroupFormField.MaximumTasks, '');
    } else {
      const maxLength = 5;
      const slicedValue = parseInt(value.length > maxLength ? value.slice(0, maxLength) : value);
      formik.setFieldValue(TaskGroupFormField.MaximumTasks, slicedValue);
    }
  };

  if (isLoadingTaskGroup || isFetchingTaskGroup) return <PageLoading />;

  return (
    <>
      <DocumentTitle>{pageTitle}</DocumentTitle>
      <ContainerTaskGroupsCreate data-testid="container-task-group-update">
        <FiltersContainer>
          <OperationSelect
            labelId={formatMessage({ id: 'formField.label.task.list.selectOperation' })}
            organization={organization.name}
            seller={seller.name}
            country={countrySelected?.label}
            disabled
          />
        </FiltersContainer>
        <FormTaskGroupsCreate onKeyDown={preventFormSubmitOnEnter} onSubmit={formik.handleSubmit}>
          <CardTaskGroupForm border={'small'} elevated={'minimal'}>
            <ContainerSelect data-testid="container-select-category">
              <InputProfileDisabled
                width={'100%'}
                data-testid="select-profile"
                value={selectedProfile}
                name={TaskGroupFormField.Profile}
                label={formatMessage({
                  id: 'app.taskGroups.fields.taskGroupForm.label.profile',
                })}
                disabled
              />
            </ContainerSelect>

            <InputTaskGroupName
              label={formatMessage({
                id: 'app.taskGroups.fields.taskGroupForm.label.name',
              })}
              data-testid="input-task-group-name"
              placeholder={formatMessage({ id: 'app.taskGroups.fields.placeholder.name' })}
              characterCounter
              width={'100%'}
              required
              name={TaskGroupFormField.Name}
              value={formik.values.name}
              onChange={formik.handleChange}
              maxLength={80}
              hasError={hasError(TaskGroupFormField.Name)}
              errorText={formatMessage({ id: 'formField.error.requiredField' })}
              disabled={isViewOnly}
            />
            <TextAreaTaskGroupDescription
              data-testid="input-task-group-description"
              label={formatMessage({
                id: 'app.taskGroups.fields.taskGroupForm.label.description',
              })}
              placeholder={formatMessage({
                id: 'app.taskGroups.fields.placeholder.description',
              })}
              width={'100%'}
              required
              name={TaskGroupFormField.Description}
              value={formik.values.description}
              onChange={formik.handleChange}
              hasError={hasError(TaskGroupFormField.Description)}
              characterCounter
              maxLength={80}
              errorText={formatMessage({ id: 'formField.error.requiredField' })}
              disabled={isViewOnly}
            />
            <WrapperInputMaxTasks>
              <InputTaskGroupMaxTasks
                label={formatMessage({
                  id: 'app.taskGroups.fields.taskGroupForm.label.maximumTasks',
                })}
                data-testid="input-task-group-max-tasks"
                placeholder={formatMessage({
                  id: 'app.taskGroups.fields.placeholder.maximumTasks',
                })}
                type={'number'}
                required
                name={TaskGroupFormField.MaximumTasks}
                value={formik.values?.maxTasksPerVisit}
                onChange={handleChangeMaxNumbers}
                hasError={hasError(TaskGroupFormField.MaximumTasks)}
                errorText={formatMessage({ id: 'formField.error.requiredField' })}
                disabled={isViewOnly}
              />
            </WrapperInputMaxTasks>
            <WrapperLocalModelCheckBox>
              {isLocalModelFlagEnabled && (
                <Checkbox
                  label={formatMessage({
                    id: 'app.taskGroups.fields.taskGroupForm.label.isLocalModel',
                  })}
                  id="local-model-checkbox"
                  disabled={true}
                  type="button"
                  data-testid="local-model-task-checkbox"
                  checked={formik.values.isLocalModel}
                />
              )}
            </WrapperLocalModelCheckBox>
          </CardTaskGroupForm>

          <FooterTaskGroupForm
            isLoading={isLoadingCreateTaskGroup}
            formType={FormTypeId.Update}
            disabled={isViewOnly}
          />
        </FormTaskGroupsCreate>
      </ContainerTaskGroupsCreate>
    </>
  );
};

export default TaskGroupUpdate;
