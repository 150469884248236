import { ThemeProvider, ThemeProviderType } from '@hexa-ui/theme';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { useHubTheme } from 'admin-portal-shared-services';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider } from 'react-redux';
import { ConditionalSidebar } from './components/ConditionalSidebar/ConditionalSidebar';
import FallbackError from './components/FallbackError';
import { FORCE_HUB_THEME } from './consts/theme';
import { GroupDataProvider } from './hooks/useContext/useGroupData/useGroupData';
import AppThemeProvider from './providers/AppThemeProvider/AppThemeProvider';
import { EnvConfig, EnvProvider } from './providers/EnvProvider';
import { IntlProvider } from './providers/IntlProvider';
import OptimizelyProvider from './providers/OptimizelyProvider';
import QueryProvider from './providers/QueryProvider/QueryProvider';
import SegmentProvider from './providers/SegmentProvider';
import UserInfoProvider from './providers/UserInfoProvider/UserInfoProvider';
import { store } from './redux/store';
import Router from './Router';

export default function App(props: EnvConfig): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-frontline-admin-portal-mfe',
    seed: 'bees-hub-frontline-admin-portal-mfe',
  });

  const [hubTheme] = useState<ThemeProviderType['theme']>(FORCE_HUB_THEME);
  useHubTheme(hubTheme);

  return (
    <ErrorBoundary FallbackComponent={FallbackError}>
      <QueryProvider>
        <EnvProvider env={props}>
          <ReduxProvider store={store}>
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProvider theme={hubTheme}>
                <AppThemeProvider>
                  <IntlProvider>
                    <GroupDataProvider>
                      <OptimizelyProvider>
                        <UserInfoProvider>
                          <SegmentProvider>
                            <>
                              <Router />
                              <ConditionalSidebar />
                            </>
                          </SegmentProvider>
                        </UserInfoProvider>
                      </OptimizelyProvider>
                    </GroupDataProvider>
                  </IntlProvider>
                </AppThemeProvider>
              </ThemeProvider>
            </StylesProvider>
          </ReduxProvider>
        </EnvProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}
