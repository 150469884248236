import taskGroupsEnUS from '../../apps/task-groups/i18n/languages/en-US';
import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  title: {
    pageTasks: 'Tasks',
    filterPhotoTasks: 'Photo tasks',
    filterSimpleSurveyTasks: 'Standard and Survey tasks',
    filterSponsoredTasks: 'Sponsored Tasks',
    modalSubmissionForm: 'Submit Task',
    modalSendRecommendedTask: 'Send recommended task',
    pageTaskCreateSimple: 'New standard task',
    pageTaskUpdateSimple: 'Update standard task',
    pageTaskDuplicateSimple: 'Standard task duplication',
    pageTaskCreateSurvey: 'New survey task',
    pageTaskUpdateSurvey: 'Update survey task',
    pageTaskDuplicateSurvey: 'Survey task duplication',
    pageTaskCreatePhoto: 'New photo task',
    pageTaskUpdatePhoto: 'Update photo task',
    pageTaskDuplicatePhoto: 'Photo task duplication',
    pageTaskRecommendCreate: 'Create recommend template',
    pageTaskRecommendUpdate: 'Update recommend template',
    pageTaskRecommendDuplicate: 'Recommend template duplication',
    pageNotFound: 'Page not found',
    pageLoading: 'Loading...',
    pageError: 'Something went wrong',
    modalDeleteTask: 'Delete this task: "{taskName}"?',
    modalDeleteSubmission: 'Delete submission: "{submissionName}"?',
    modalConfirmDefault: 'Confirmation',
    mandatory: 'Mandatory?',
    formTask: 'General',
    formTaskSub: 'Define the task content and sponsorship',
    formValidation: 'Validation conditions',
    effectivenessCheck: 'Validation conditions',
    modalSaveDraft: 'Save as draft?',
  },
  subtitle: {
    modalSubmissionForm: "Define the submission's name and its period",
    coverageItemCard: 'SKU ID: {skuId}',
  },
  table: {
    header: {
      taskSubmissions: 'Task submissions',
      taskSubmissionStatus: 'Status',
      taskSubmissionValidity: 'Validity',
    },
  },
  sidebar: {
    title: {
      pageTasks: 'Tasks management',
      pageOperations: 'Operation panel',
      pageTaskGroups: 'Task groups',
      pageAudienceBuilder: 'Audience builder',
    },
  },
  tooltip: {
    default: 'View this tip',
    menu: 'Menu',
    expandTask: 'Expand task',
    noPermission: "Don't have permission",
    submissionActives: 'Templates with active submission cannot be deleted',
    categoryDeprecated: 'The selected task category is no longer available. Select another one',
    localModelIdDeprecated:
      'The selected local model ID is no longer available. Select another one',
    viewOnly: 'View only',
    task: {
      prioritize: 'Prioritize this task to submit it',
      globalTask: 'Use this tag if this task is related to the BEES Global Strategy.',
      category: {
        disabled: '{profile} cannot perform {category}',
      },
      subCategory: {
        disabled: '{subCategory} is not available',
        disabledInCategory: '{subCategory} is not available in {category}',
      },
      simple: {
        effectivenessCheck: {
          categoryNeeded:
            "It's necessary to select a category before adding the effectiveness check",
          subCategoryDisabled: '{subCategory} only available in {categories}',
          addId: 'Use Enter to add IDs or Comma to separate values',
          addIdManualOrCsv:
            'Use Enter to add IDs or Comma to separate values or submit a CSV. To check how the file is built, download the model below',
        },
      },
      sponsored: 'Sponsored tasks must have a sponsor partner defined',
      survey: {
        reorder: 'Reorder',
      },
      photo: {
        addVisionJobIdButton: 'Type or paste a vision job id to proceed',
        visionJobIdSelected:
          'Vision job id already selected, to add another one, please delete the current vision job id',
      },
    },
    submission: {
      delete: 'Cancel submission',
      downloadCsv: 'Download POC list',
      csvFirst:
        'You can generate the account ID list using your local database, or fill the list manually with the account IDs in a spreadsheet.',
      csvSecond:
        'After that insert the CSV file here. To check how the file is built, download the model below.',
      validityFirst:
        'The validity is the period the task is available for the BDRs to answer/ execute.',
      validitySecond: 'If the task has to valid for just one day, select only the start date.',
    },
    priorization: {
      needChange: 'Change prioritization to confirm',
      confirm: 'Confirm',
      reorder: 'Reorder',
      cancel: 'Cancel',
    },
    taskForm: {
      controlSettings: 'Edit Profile and Operation on Task management page.',
    },
  },
  popper: {
    task: {
      duplicate: {
        reminder: {
          title: 'Friendly reminder:',
          message: 'Change the Task title to differentiate from the one you duplicated.',
        },
      },
      viewOnly: {
        reminder: {
          title: 'Friendly reminder:',
          message: 'This page is view only and the data cannot be changed.',
        },
      },
    },
    submission: {
      create: {
        validity:
          'The validity is the period the task is available for the BDRs to answer/ execute. If the task has to be valid for just one day, select only the start date.',
        dropzoneFile:
          'You can generate the account ID list using your local database, or fill the list manually with the account IDs in a spreadsheet. After that insert the CSV file here. To check how the file is built, download the model below.',
      },
    },
  },
  toast: {
    success: {
      task: {
        create: 'Task created successfully!',
        update: 'Task updated successfully!',
        delete: 'Task deleted successfully!',
        priority: 'Priority updated successfully!',
      },
      submission: {
        create: 'Submission created successfully!',
        delete: 'Submission deleted successfully!',
      },
      recommendedSubmission: {
        create: 'Task successfully sent!',
      },
    },
    error: {
      multipleFilesNotAllowed: 'Cannot add more than one file',
      task: {
        create: 'An error occurred while creating the Task. Please, try again later.',
        update: 'An error occurred while updating the Task. Please, try again later.',
        delete: 'An error occurred while deleting the Task. Please, try again later.',
        getTaskData: 'An error occurred while getting Task data. Please try again later.',
        effectivenessCheck: {
          idAlreadyAdded: 'This ID has already been added!',
        },
        priority: 'An error occurred while updating the Priority. Please, try again later.',
        invalidFrequency: 'The frequency was invalid, please select one of the available options.',
        unavailableMonthlyFrequency:
          'Coverage tasks do not work, 30 days frequency must be registered on the back-end for this vendorGroupID',
      },
      submission: {
        create: 'An error occurred while creating the Submission. Please, try again later.',
        delete: 'An error occurred while deleting the Submission. Please, try again later.',
      },
    },
    warning: {
      task: {
        requiredField: 'Required fields not fulfilled! Please, check the form before submit again.',
      },
    },
  },
  comboBox: {
    noOptions: 'No options found',
  },
  message: {
    desc: {
      deleteTask: 'If you do, all these task submissions will be deleted.',
      deleteSubmission:
        "Today's task will not be deleted. Starting tomorrow, no new tasks will be generated for the BDRs.",
      modalConfirmDefault: 'Are you sure you want to do this?',
      categoryPhoto: '*For photo tasks, execution is the only accepted category.',
    },
    loading: {
      default: 'Loading...',
      waitAMoment: 'Wait a moment...',
      validating: 'Validating, wait a moment...',
    },
    success: {
      fileValidate: 'File validated!',
      priorityUpdated: 'Priority updated',
      validTaskCategory: 'Valid task category selected.',
    },
    error: {
      default: 'Ooops! Something went wrong',
      request: 'An error occurred with your request. Please, try again later.',
      notFound: 'Mmm… Looks like this page is gone',
      notFoundSuggestion: "It looks like this page wasn't found or doesn't exist.",
      userWithoutGroups: 'Your user does not have permission groups',
      userWithoutVendorId: 'Your user does not have a Company ID',
      userWithoutCountry: 'Your user does not have a Country',
      userWithoutCountryAndVendor: 'Your user does not have a Country and Company ID',
      userWithoutProfile:
        'An error occurred while requesting your profile data. Please check if the data is configured correctly.',
      userWithoutTaskGroup:
        'An error occurred while requesting your task groups data. Please check if the data is configured correctly.',
      userWithoutTaskTypes:
        'An error occurred while requesting your task types data. Please check if the data is configured correctly.',
      userWithoutTaskCategories:
        'An error occurred while requesting your task categories data. Please check if the data is configured correctly.',
      requestUserInfo:
        'An error occurred with the request of your user data. Please, try again later.',
      getTaskOptions: 'Sorry! Options not found. Please, try again by',
      optimizelyNotLoaded:
        'Sorry! The connection to the optimizely service was not successful. Please try again',
      withoutResults: 'Oops, sorry! No results found',
      searchEmptyList: 'Oops, sorry! No results found for',
      searchEmptyListSuggestion: 'Make sure you typed the words correctly.',
      tasksNotFound: 'Oops! There are no tasks to be listed.',
      tasksNotFoundSuggestion: 'Try changing the selected filter or create a new task.',
      vendorUndefinedTaskList: 'Select a country and vendor available.',
      emptyFile: 'The file is empty',
      dropzoneEmpty: 'No files have been inserted. ',
      dropzoneEmptySuggestion: 'Try to insert something!',
      invalidCsvTask: 'Please insert a valid .CSV file before submitting the task. ',
      fileWrongExtension: 'Wrong file extension',
      fileMoreThanOneColumn: 'File has more than one column',
      fileWrongColumnName: 'Wrong column name',
      multipleAnswer: 'Please, add at least two answers to this question.',
      duplicatedAnswer: 'Answers cannot be equal. Please, type different content for each answer.',
      visionJobIdNotValid: 'ERROR: Vision Job ID selected invalid',
      selectVisionJobIdValid: 'Please type or paste another vision Job ID',
      duplicatedBdrId: 'BDR ID already entered. Try another one.',
      invalidBdrId: 'BDR ID not found. Enter another one.',
      invalidSku: 'SKU not found. Enter another one.',
      someSkuInvalid: 'Some SKUs are invalid, download the report for detailed information.',
      allSkuInvalid: 'All SKUs are invalid, download the report for detailed information.',
      operationNotFound: 'Error retrieving operation information.',
      subtypeVolumeNotFound:
        'Cannot create the portfolio model task because this vendorGroupId does not have the subType corresponding to VOLUME created',
    },
    alert: {
      setTaskPriority: 'Set the priority of this task',
      deprecatedTaskCategory:
        'The selected task category is no longer available. Please, select another one.',
      reviewUpdates:
        'A new update was submitted by your organization. Review all new template suggestions.',
    },
  },
  button: {
    proceed: 'Proceed',
    newTask: 'Create new task',
    newPrioritization: 'Send new prioritization',
    newRecommendTask: 'Create recommend template',
    recommendedUpdates: 'Recommended updates',
    reviewUpdates: 'Review updates',
    newSubmission: 'Submit task',
    openCancellationRequest: 'Open request',
    updateCancellationRequest: 'Update request',
    cancel: 'Cancel',
    confirm: 'Confirm',
    apply: 'Apply',
    saveChanges: 'Save changes',
    tryAgain: 'Try again',
    backToHome: 'Back to home',
    tryAgainTaskOptions: 'clicking here.',
    goBackTask: 'Go back',
    createTask: 'Create task',
    saveCreateTask: 'Save task',
    addAudienceCheck: 'Add audience check',
    addEffectivenessCheck: 'Add conditions',
    clearSearch: 'Clear search',
    clearSearchFilters: 'Clear filters',
    clearFilters: 'Clear all',
    viewOnlyTask: 'View',
    viewAndEditTask: 'View and Edit',
    duplicateTask: 'Duplicate',
    deleteTask: 'Delete',
    deleteSubmission: 'Delete submission',
    send: 'Submit',
    downloadModel: 'Download model',
    browseFiles: 'Browse in your device',
    newQuestion: 'Add new question',
    tabs: {
      submit: 'Send',
      priority: 'Priority',
    },
    jumpToQuestion: 'Jump to question',
    endQuestionary: 'Finish questionary',
    addNewAnswer: 'Add new answer',
    addVisionJobId: 'Add',
    filter: 'Filters',
    openRequest: 'Open request',
    cancelTask: 'Cancel task',
    downloadCSV: 'Download CSV',
    discard: 'Discard',
    save: 'Save',
    downloadReport: 'Download Report',
    addOperation: 'Add condition',
    gobacktoedit: 'Go back to edit',
    goToAudienceBuilder: 'Go to Audience Builder',
  },
  formField: {
    placeholder: {
      submission: {
        name: 'Enter the submission name',
        validity: 'Select the validity period',
        drop: 'Drop here a .CSV file with the account IDs or',
      },
      formulaOperation: {
        selectFeature: 'Select feature',
        selectcombo: 'Select combo',
        selectchallenge: 'Select a challenge',
        selectOperator: 'Select operator',
        selectCondition: 'Select condition',
        selectValue: 'Select value',
        value: 'Example: 5',
        selectPeriod: 'Select period',
        periodNotAvailable: 'Period not available',
      },
      task: {
        search: 'Search for task',
        name: 'Enter a task name',
        instruction: 'Enter task instructions',
        category: 'Select a category',
        taskEffective:
          'Enter the text that will be displayed in the task history when the task was valid',
        taskPending:
          'Enter the text that will be displayed in the task history while validation is pending',
        taskNotEffective:
          'Enter the text that will be displayed in the task history when the task is invalidated',
        controlTags: 'Enter control tags. Example: Campaign, VIP, brands, etc',
        optionalText: '(Optional)',
        estimatedTime: 'Example: 5',
        selectValue: 'Select an option',
        taskGroup: 'Select a task group',
        simple: {
          audienceCheck: {
            main: "Define the conditions that make this task valid. If you don't add any, the task will be automatically validated",
          },
          effectivenessCheck: {
            main: 'If the validation conditions is not added, the task will be validated at the moment the {profile} completes it.',
            id: {
              MONTHLY_PRODUCT_COVERAGE: 'Add the SKUs of Coverage',
              COMBO: 'Add Combo ID',
              CHALLENGE: 'Add Challenge ID',
              VOLUME: 'Add the SKUs of Volume',
            },
            minimumQuantity: 'Enter minimum amount',
          },
          selectModelId: 'Select a Local Model ID:',
        },
        survey: {
          inputAnswerFreeText: 'BDR Answer (Free Text)',
          inputAnswerNumeric: 'BDR Answer (Numeric)',
          questionTitle: 'Type the question',
          selectValue: 'Select a Value',
          optionValue: 'Type the answer',
          selectCondition: 'Select condition',
        },
        photo: {
          inputVisionJobId: 'Type or paste the Vision Job ID',
          visionJobIdSelected: 'Vision Job ID selected',
        },
      },
      sponsored: {
        search: 'Select an option or type to search',
      },
    },
    label: {
      submission: {
        name: 'Submission name',
        validity: 'Validity',
        audienceFilters: 'Audience filters',
        inclusionList: 'Inclusion list',
        exclusionList: 'Exclusion list',
      },
      recommendedSubmission: {
        destinationGroup: 'Destination group',
        destinationGroupHint: 'Sellers eligible to receive this task.',
      },
      formulaOperation: {
        selectOperator: {
          equalsToLabel: 'Equals to',
          greaterThanLabel: 'Greater than',
          greaterOrEqualsToLabel: 'Greater or equals to',
          lessThanLabel: 'Less than',
          lessOrEqualsToLabel: 'Less or equals to',
          label: 'Operator',
        },
        selectCondition: {
          andLabel: 'AND',
          orLabel: 'OR',
        },
        combo: 'Combo',
        challenge: 'Challenge',
        selectFeature: 'Feature',
        products: 'Products',
        product: 'Product',
        selectProducts: 'Select products',
        quantityIndicatorIntlText: 'of',
        selectValue: 'Value',
        selectPeriod: {
          label: 'Period',
        },
      },
      task: {
        sponsor: 'Partner',
        name: 'Task name',
        taskNameHint: 'This name will be displayed on BEES Force admin portal.',
        instruction: 'Task instruction',
        category: 'Task category',
        subCategory: 'Choose a task sub-category:',
        categoryHint: 'The area of business this task should impact',
        taskEffective: 'Validated task text',
        taskPending: 'Pending task text',
        taskNotEffective: 'Invalid task text',
        globalTask: 'Global Task',
        controlTags: 'Control tags',
        estimatedTime: 'Task estimated time',
        frequencySelector: 'Task frequency',
        taskGroup: 'Task group',
        taskGroupHint: 'The group to which the task will be allocated in order to be triggered.',
        instructionHint: 'This description will be displayed to the BDR on BEES Force app.',
        list: {
          selectProfileListTasks: 'Profile',
          selectOperation: 'Operation',
          selectOperationTooltip: 'The operation includes the organization and seller.',
          selectTaskType: {
            label: 'TASK TYPE:',
            sponsored: 'Sponsored',
            nonSponsored: 'Non-Sponsored',
          },
        },
        create: {
          selectProfile: 'Who will perform this task?',
          selectTaskType: 'Which type of task do you want to create?',
        },
        simple: {
          duration: 'Select an estimated time to complete this task:',
          frequency: 'Select a frequency that this task should be available for the BDR:',
          audienceCheck: 'Audience',
          audienceLabel: 'Define what POCs is this task targeting.',
          audienceInfo: 'Include POCs',
          effectivenessCheck: 'Validation conditions',
          effectivenessId: {
            CHALLENGE: 'Insert the Challenge ID',
            COMBO: 'Insert the Combo ID',
            MONTHLY_PRODUCT_COVERAGE: 'SKU for validation',
            VOLUME: 'SKU for validation',
          },
          minimumQuantity: 'Minimum amount',
          scheduleAndFrequency: {
            title: 'Time',
            subtitle: 'Define task duration, frequency, and estimated completition time.',
            toggle: {
              title: 'Enable time discounting',
              subtitle:
                'When enabled, time spent on this task will be discounted from the total visit time.',
              description: 'Enabled',
            },
            info: 'Set task estimated time considering the total visit time. This helps distribute tasks among BDRs and ensures everyone has enough time to complete them.',
          },
          selectModelId: 'Select a Local Model ID:',
        },
        sponsored: 'Sponsored task',
        sponsoredPartnerLabel: 'Partner',
        sponsoredPartnerParagraph: 'Select partner sponsoring this task template',

        survey: {
          questionary: {
            questionTitle: 'Question number',
          },
        },
        photo: {
          inputVisionJobId: 'Add Vision Job ID',
        },
      },
    },
    value: {
      task: {
        type: {
          SIMPLE: 'Standard',
          SURVEY: 'Survey',
          IMAGE_RECOGNITION: 'Photo',
        },
        category: {
          CUSTOMER_SATISFACTION: 'Customer Satisfaction',
          DIGITAL_EDUCATION: 'Digital Education',
          EXECUTION: 'Execution',
          VALUE_CREATION: 'Value Creation',
          PORTFOLIO_EXPANSION: 'Portfolio Expansion',
          VOLUME_REVENUE: 'Volume & Revenue',
          MARKETPLACE_PORTFOLIO_EXPANSION: 'Marketplace Portfolio Expansion',
          MARKETPLACE_REVENUE: 'Marketplace Revenue',
          EXECUTION_ABI: 'Execution ABI',
          EXECUTION_MARKETPLACE: 'Execution Marketplace',
          MI_NEGOCIO: 'Mi Negocio',
          FINTECH: 'FinTech',
          CHALLENGE_REWARDS: 'Challenge and Rewards',
          ORDER_BEES: 'Order on BEES',
          DTAAS: 'DTaaS',
          SURVEY_DATA_COLLECTION: 'Survey/Data Collection',
          OTHER_TASKS: 'Other Tasks',
        },
        subCategory: {
          CHALLENGE: 'Challenge',
          COMBO: 'Combo',
          MONTHLY_PRODUCT_COVERAGE: 'Coverage',
          SHELF: 'Shelf',
          COOLER: 'Cooler',
          POSTER: 'Poster',
          VOLUME: 'Volume',
        },
        feature: {
          account_status: 'Account status (active or blocked)',
          sub_segment: 'Subsegment (text)',
          channel: 'Channel (text)',
          potential: 'Potential (text)',
          is_key_account: 'Key account (true or false)',
          has_overprice: 'Overprice (true or false)',
          has_empty_loan: 'Empty loan (true or false)',
          segment: 'Poc segment (text)',
          days_last_order: 'Days since last any marketplace order (number)',
          qty_skus_mp_l15d: 'Marketplace SKUs ordered (number)',
          qty_skus_mp_l30d: 'Marketplace SKUs ordered (number)',
          qty_skus_mp_l60d: 'Marketplace SKUs ordered (number)',
          qty_skus_mp_last_order: 'Marketplace SKUs in the last order (number)',
          avg_skus_mp_per_order_l30d: 'Average marketplace SKUs (number)',
          mdn_skus_mp_per_order_l30d: 'Median marketplace SKUs (number)',
          mkp_revenue_l30d: 'Marketplace revenue (number)',
          mkp_revenue_l60d: 'Marketplace revenue (number)',
          qty_digital_orders_l30d: 'Digital orders (number)',
          qty_digital_orders_l60d: 'Digital orders (number)',
          non_digital_revenue_l30d: 'Non-digital revenue (number)',
          digital_revenue_l30d: 'Digital net revenue (number)',
          total_revenue_l30d: 'Total net revenue (number)',
        },
        feature_value: {
          true: 'True',
          false: 'False',
          active: 'Active',
          blocked: 'Blocked',
          inactive: 'Inactive',
        },
        period: {
          l60d: 'Last 60 days',
          l30d: 'Last 30 days',
          l15d: 'Last 15 days',
          tmth: 'This month',
          lmth: 'Last month',
        },
        simple: {
          frequency: {
            daily: 'Every visit',
            weekly: 'Weekly',
            everyVisit: 'Every visit',
            monthly: 'Every 30 days',
            once_a_month: 'Once a month',
          },
        },
        survey: {
          responseType: {
            numeric: 'Numeric',
            freeText: 'Free Text',
            checkBox: 'Multi-select',
            radioButton: 'Single-select',
            conditional: 'Conditional',
          },
        },
      },
      selectProfile: {
        bdr: 'BDR',
        merchandiser: 'Merchandiser',
        negbdr: 'BDR Negotiation',
        business_executive: 'Business Executive',
        brfbdr: 'BDR BRF',
      },
    },
    tip: {
      task: {
        type: {
          SIMPLE:
            'The standard task is an action for the BDRs to execute during the visit to the POC. This type consists of a title and an instruction.',
          SURVEY:
            'The survey type consists of several questions for BDRs to answer during the visit to the POC. The questions can have different types of answers, such as multiple selections, single selection, free text, numeric and boolean.',
          IMAGE_RECOGNITION:
            'The photo task type enables the BDRs to easily register the POC assets like coolers or trade market materials by using the camera built-in in their devices. Photo task type is also connected to Image Recognition intelligence which allows automating the effectiveness check operation.',
        },
        simple: {
          frequency: {
            everyVisit:
              'Every visit: The task will be available for every visit in the POC. However, this task can be replaced by another task with a highest priority.',
            monthly:
              'Every 30 days: The task will be available to BDR within 30 days between visits.',
          },
        },
      },
    },
    button: {
      changeDefaultMessages: 'Edit default task validation messages',
    },
    error: {
      requiredField: 'This field is required',
      minValue: 'Please enter a value greater than or equal to {value}',
      maxValue: 'Please enter a value less than or equal to {value}',
      lessOperator: 'Enter a value greater than {value}',
      greaterOperator: 'Enter a value less than {value}',
      greaterZero: 'Please enter a value greater 0',
      task: {
        requiredDuration: 'Please, choose a estimated time before saving',
        requiredFrequency: 'Please, choose a frequency before saving',
        requiredCategory: 'Please, choose a task category before saving',
        requiredSubCategory: 'Please, choose a sub-category before saving',
        requiredEffectivenessIDs: 'Please, insert the IDs before saving',
        requiredMinimumQuantity: 'Please, type an minimum amount before saving',
        requiredSponsoredBy: 'Please, choose a partner before saving',
        addVisionJobId: 'To add the selected vision job id, click on the "Add" button',
        localModelIdListEmpty: 'There are no valid modelIDs configured for this vendor group',
      },
    },
    hint: {
      task: {
        inMinutes: '(In minutes)',
        simple: {
          effectivenessCheck: {
            minimumQuantity: 'Enter a value up to 9999',
          },
          inputAnalyticTags: 'Use commas to separate values ​​and spaces will be replaced by dash.',
        },
      },
    },
    defaultValues: {
      validEffectiveness: 'Task Completed. Good Job!',
      invalidEffectiveness: 'The task was not completed.',
      pendingEffectiveness: 'The task validation is still pending.',
    },
  },
  submission: {
    noneSubmission: 'None submissions',
    status: {
      FINISHED: 'Finished',
      SCHEDULED: 'Scheduled',
      ACTIVE: 'Active',
      CONCLUDED: 'Finished',
      INVALID: 'Invalid',
      INVALIDS: 'Invalid',
      INPROGRESS: 'Processing',
      IN_PROGRESS: 'Processing',
      PENDING: 'Pending',
      FINALIZED: 'Finalized',
      SUBMITTED: 'Submitted',
    },
    list: {
      table: {
        title: 'Task submissions',
        status: 'Status',
        validity: 'Validity',
      },
      dateFormat: 'MMM dd, yyyy',
      noValidity: 'No validity',
    },
    submit: {
      messages: {
        error: {
          finishedErrorAccountFoundDifferent:
            'Finished with error, {accountFound} of {accountSize} succeeded',
          finishedErrorAccountDuplicated:
            'Finished with {accountDuplicated} of {accountSize} duplicated, {accountSubmitted} submitted',
          finishedErrorAccountInvalidAccountDuplicated:
            '{accountNotFound} invalids, {accountDuplicated} duplicated, {accountSubmitted} submitted',
          invalidItems: 'Error with the CSV file, invalid items',
        },
        inProgress: {
          default: 'In progress: {submissionProgress}',
        },
        success: {
          default: 'CSV processed with success',
        },
      },
    },
  },
  csv: {
    header: {
      accountsId: 'accountId',
      accountsJustified: 'justified',
      accountsAnswered: 'answered',
      accountsNotAnswered: 'not answered',
      accountsCancelled: 'canceled',
      accountsCreated: 'created',
    },
    content: {
      answerYes: 'yes',
      answerNo: 'no',
    },
    footer: {
      total: 'total',
    },
  },
  pagination: {
    pageSize: 'Show {pageSize} items per page',
  },
  emptyState: {
    title: {
      noRequests: 'No requests yet',
    },
    desc: {
      noRequests: 'Your future requests will be displayed here.',
    },
  },
  app: {
    taskGroups: { ...taskGroupsEnUS },
  },
  configureOperationModal: {
    title: 'Edit operation',
    description: 'The operation will be applied to the task page.',
    cancel: 'Cancel',
    confirm: 'Save',
    titleCountryInput: 'Country',
    countryPlaceholder: 'Select an option',
    titleOrganizationInput: 'Organization',
    subTitleOrganizationInput: 'May include companies and country.',
    organizationPlaceholder: 'Select an organization',
    titleSellerInput: 'Seller',
    subTitleSellerInput: 'Who will receive the task.',
    sellerPlaceholder: 'Select a seller',
  },
  drawerComponent: {
    title: 'Select products',
    search: 'Search by SKU name or ID',
    emptyMessage: 'Search for the products you want to include in your variable.',
    emptyMessageFiltered:
      'Check the spelling or try different keyworkds. SKUs might be already added.',
    cancelButton: 'Cancel',
    addSelectedButton: 'Add selected SKU',
    noResultsFound: 'No results found',
    nestedTitle: 'Selected SKUs',
    nestedRemoveAll: 'Remove all',
    nestedSkusSelected: '{totalSelected} SKUs selected',
    finishSelection: 'Finish selection',
  },
  drawerComponentTaskList: {
    detailsTabTitle: 'Details',
    detailsCancelBtn: 'Reject',
    detailsUpdateBtn: 'Accept',
    btnShowReviewedItems: 'Show reviewed items',
    btnReview: 'Review',
    btnChangeReview: 'Review again',
  },
  audienceSelect: {
    title: {
      dynamic: 'Select filters',
      static: 'Select POC list',
    },
    subTitle: {
      dynamic: "Set parameters to define POC's that should be impacted by this task.",
      static: 'Select a predefined POC list or upload a new one on Audience builder.',
    },
    fields: {
      label: {
        vendor: 'Seller',
      },
      placeholder: {
        vendor: 'Select an option',
      },
    },
    info: {
      audienceStaticInfo:
        'You must go to Audience builder to upload a new POC list. Processing a list may take a few hours.',
    },
    error: {
      audienceType: 'Selection is required to continue.',
    },
    btnAddStaticAudience: 'Add new audience',
  },
};

export default enUS;
