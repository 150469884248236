import { Frequency } from './frequency';
import { Feature } from './groupedFeatures';

export enum FeatureId {
  SimpleCoverage = 'tmth_qty_coverage_skus',
}
export enum CoverageFeatureId {
  SimpleCoverage = 'l60d_qty_coverage_skus',
}

export enum LocalModelTaskInstuction {
  message = 'Local Model Task',
}

export enum FeatureType {
  SimpleCoverage = 'coverage',
  Combo = 'combo',
  Challenge = 'challenge',
}

export enum FeatureMultiselect {
  Combo = 'COMBO',
  Challenge = 'CHALLENGE',
}

export enum EffectiveOrAudienceFeature {
  Audience = 'audience',
  Effectiveness = 'effectiveness',
}

export interface GetFeatureMultiselectMetadata {
  signal?: AbortSignal;
  featureType: 'COMBO' | 'CHALLENGE';
  page: number;
  metadataId?: string;
}

export interface FeatureMultiselectMetadada {
  id: string;
  metadataId: string;
  vendorGroupId: string;
  country: string;
  type: 'COMBO' | 'CHALLENGE';
  deleted: boolean;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface GetFeaturesMultiselectMetadataResponse {
  content: FeatureMultiselectMetadada[];
  pagination: Pagination;
}

export interface ActivityList {
  content: Activity[];
  pagination: Pagination;
}

export interface LocalModel {
  active: boolean;
  country: string;
  endDate: string;
  id: string;
  modelId: string;
  startDate: string;
  vendorGroupId: string;
}
export interface LocalModelList {
  content: LocalModel[];
  pagination: Pagination;
}
export interface GetLocalModels {
  signal?: AbortSignal;
  active?: boolean;
  page?: number;
  pageSize?: number;
  country?: string;
  modelId?: string;
}
export interface GetSubtypes {
  signal?: AbortSignal;
  page?: number;
  pageSize?: number;
  country?: string;
  vendorGroupId: string;
}
export interface GetSimpleCoverageProductsList {
  body: PayloadSimpleCoverageProductsList;
  country: string;
  xAppName: string;
  originSystem: string;
  acceptLanguage: string;
}
export interface PayloadSimpleCoverageProductsList {
  vendorIds: string[];
  query: string;
}

export interface Activity {
  activityTemplateId: string;
  name: string;
  priority?: number;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile;
  durationInMinutes: number;
  activityTemplateCategory: ActivityCategory;
  activitySubtype?: ActivitySubtype;
  tags: string[];
  isSponsored: boolean;
  sponsoredBy: string;
  sponsoredById: string;
  campaignId?: string;
  frequency: Frequency;
  activityType: ActivityType;
  activityParams?: { key?: string };
  audienceTemplate: ConfigActivityDynamicAudienceTemplate;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple;
  createAt: string;
  updateAt: string;
  updateBy: string;
  isCategoryValid?: boolean;
  vendorId?: string;
  vendorGroupId?: string;
  visionRuleId?: string;
  activityTemplateGroupId?: string;
  localModel?: ConfigTaskLocalModel;
  survey?: Survey[];
}

export interface ActivitySurvey {
  activityTemplateId: string;
  name: string;
  priority: number;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile;
  durationInMinutes: number;
  activityTemplateCategory: ActivityCategory;
  activitySubtype?: ActivitySubtype;
  tags: string[];
  isSponsored: boolean;
  sponsoredBy: string;
  sponsoredById: string;
  campaignId?: string;
  frequency: Frequency;
  activityType: ActivityType;
  activityParams?: { key?: string };
  audienceTemplate: ConfigActivityDynamicAudienceTemplate;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple;
  createAt: string;
  updateAt: string;
  updateBy: string;
  isCategoryValid?: boolean;
  vendorId?: string;
  survey: Survey[];
  activityTemplateGroupId?: string;
}

export interface ActivityPhoto {
  activityTemplateId: string;
  isActive: boolean;
  durationInMinutes: number;
  frequency: Frequency;
  tags: string[];
  activityType: ActivityType;
  templateTitle: string;
  name: string;
  priority: number | null;
  categoryId: string;
  vendorId: string;
  updatedAt: string;
  updatedBy: string;
  updatedById: string;
  createdAt: string;
  createdBy: string;
  createdById: string;
  audienceTemplate: ConfigActivityDynamicAudienceTemplate;
  isSponsored: boolean;
  sponsoredBy: string | null;
  sponsoredById: string | null;
  taskType: TaskType;
  activityTemplateCategory: ActivityCategory;
  visionRuleId: string;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile;
  activityTemplateGroupId?: string;
}

export interface CreateActivitySimple {
  body: PayloadActivitySimple;
  vendorGroupId?: string;
}

export interface CreateActivitySurvey {
  body: PayloadActivitySurvey;
  vendorGroupId?: string;
}

export interface CreateActivityPhoto {
  body: PayloadActivityPhoto;
}

export interface SubTaskOptionsConditional {
  position?: number;
  id?: string;
}

export interface SubTasksOptions {
  id: string | undefined;
  value: string;
  jump?: SubTaskOptionsConditional;
}

export interface Survey {
  id: string;
  title: string;
  isRequired: boolean;
  responseType: SurveyQuestionResponseId;
  options?: SubTasksOptions[];
}

export interface PayloadActivitySurvey {
  name: string;
  requiredDuration: boolean;
  durationInMinutes?: number;
  frequencyId: string;
  activityTemplateCategoryId: string;
  isSponsored: boolean;
  sponsoredBy?: string | null;
  sponsoredById?: string | null;
  tags?: string[];
  activityTemplateGroupId: string;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile[];
  audienceType: string;
  audienceTemplate: ConfigActivityAudience[] | StaticAudience[] | any;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple[] | any;
  survey: Survey[];
}

export interface UpdateActivitySurvey {
  activityId: string;
  body: PayloadActivitySurvey;
}
export interface UpdateActivityPhoto {
  activityId: string;
  body: PayloadActivityPhoto;
}

export interface ActivityConfigurationPerProfile {
  profileId: string;
  message: string;
  validEffectiveness?: string;
  invalidEffectiveness?: string;
  pendingEffectiveness?: string;
}

export interface ActivityProfileList {
  content: ActivityProfile[];
  pagination: Pagination;
}

export interface ActivityProfile {
  profileId: string;
  name: string;
  title?: string;
}

export interface PayloadActivitySimple {
  name: string;
  requiredDuration: boolean;
  durationInMinutes?: number;
  frequencyId: string;
  activityTemplateCategoryId: string;
  isSponsored: boolean;
  sponsoredBy?: string | null;
  sponsoredById?: string | null;
  tags?: string[];
  activityTemplateGroupId: string;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile[];
  audienceType: string;
  audienceTemplate: ConfigActivityAudience[] | StaticAudience[] | any;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple[] | any;
  activitySubtypeId?: string;
  activityTypeId?: string;
  modelId?: string;
  campaignId?: string;
}

export interface PayloadActivityPhoto {
  name: string;
  requiredDuration: boolean;
  durationInMinutes?: number;
  frequencyId: string;
  activityTemplateCategoryId: string;
  isSponsored: boolean;
  sponsoredBy?: string | null;
  sponsoredById?: string | null;
  tags?: string[];
  activityTemplateGroupId: string;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile[];
  effectivenessTemplate?: ConfigActivityEffectivenessSimple[] | any;
  audienceType: string;
  audienceTemplate: ConfigActivityAudience[] | StaticAudience[] | any;
  visionRuleId: string;
}

export interface CreateActivitySimpleResponse {
  activityTemplateId: string;
}

export interface UpdateActivityResponse {
  activityTemplateId: string;
}

export interface ConfigActivityAudience {
  condition?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
  feature: string;
  operator: string; //TODO:  (BEESOT-41964) - Change this type by enum or interface
  value: any;
  period?: any;
  minimumValue?: any;
  maximumValue?: any;
  requiredPeriod?: any;
  skus?: any;
  metadataIds?: any;
  featureType?: string;
}

export interface ConfigActivityEffectivenessSimple {
  featureType: string;
  condition?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
  feature: string;
  operator: string; //TODO:  (BEESOT-41964) - Change this type by enum or interface
  value: any;
  period?: any;
  minimumValue?: any;
  maximumValue?: any;
  requiredPeriod?: any;
  skus?: any;
  metadataIds?: any;
}

export interface ConfigActivityDynamicAudienceTemplate {
  audienceType: AudienceTypes;
  rule: {
    feature?: string;
    operator?: string;
    value?: string | number | boolean | string[];
    logicalOperator?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
    arguments?: ConfigActivityAudience | ConfigActivityAudience[];
    featureType?: string;
    skus?: string[];
    metadataIds?: string[];
  };
}

export interface ActivityState {
  availableActivityTypes?: ActivityType[];
  availableActivityCategories?: ActivityCategory[];
  availableSubTypes?: SubType[];
  featureList?: Feature[];
  featureMultiselectMetadata?: FeatureMultiselectMetadada[];
  availableVisionConfigs?: string[];
}

export interface ActivityTypeList {
  content: ActivityType[];
  pagination: Pagination;
}

export interface ActivityType {
  activityTypeId?: string;
  name?: ActivityTypeName;
  icon?: string;
  title?: string;
}

export interface ActivityCategoryList {
  content: ActivityCategory[];
  pagination: Pagination;
}

export interface ActivityCategory {
  id?: string;
  activityTemplateCategoryId?: string;
  category: string;
  title?: string;
}

export interface ActivitySubtypeList {
  content: ActivitySubtype[];
  pagination: Pagination;
}

export interface ActivitySubtype {
  activitySubtypeId: string;
  name: string;
  title: string;
}

export interface Pagination {
  page: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

export interface SubType {
  id: SubTypeName;
  addMethods: AddMethod[];
  effectivenessFormField: OperationFormField;
}

export enum TaskTypeId {
  Simple = 'SIMPLE',
  Survey = 'SURVEY',
  Photo = 'IMAGE_RECOGNITION',
}

export interface TaskType {
  id: TaskTypeId;
  icon: string;
}

export enum EffectivenessPhotoFormField {
  VisionConfigId = 'visionConfigId',
}

export interface ConfigTaskEffectivenessPhoto {
  visionConfigId: string;
}

export enum ActivityFormFieldPhoto {
  ImageRecognitionBucket = 'imageRecognitionBucket',
  ConfigEffectiveness = 'configTaskEffectiveness',
  VisionRuleId = 'visionRuleId',
}

export enum ImageRecognitionBucketId {
  Shelf = 'SHELF',
  Poster = 'POSTER',
  Cooler = 'COOLER',
  None = 'NONE',
  Empty = '',
}

export enum ActivityTypeName {
  Simple = 'SIMPLE',
  Survey = 'SURVEY',
  Photo = 'IMAGE_RECOGNITION',
}

export enum SubTypeName {
  Challenge = 'CHALLENGE',
  Combo = 'COMBO',
  Coverage = 'MONTHLY_PRODUCT_COVERAGE',
  Volume = 'VOLUME',
  Empty = '',
}

export enum AddMethod {
  Manual = 'MANUAL',
  Csv = 'CSV',
}

export enum ActivityFormField {
  ActivityTemplateId = 'activityTemplateId',
  Name = 'name',
  ProfileId = 'profileId',
  Profile = 'profile',
  IsSponsored = 'isSponsored',
  SponsoredBy = 'sponsoredBy',
  SponsoredById = 'sponsoredById',
  DurationInMinutes = 'durationInMinutes',
  FrequencyId = 'frequencyId',
  ActivityTemplateCategoryId = 'activityTemplateCategoryId',
  AudienceTemplate = 'audienceTemplate',
  EffectivenessTemplate = 'effectivenessTemplate',
  ActivityConfigurationPerProfile = 'activityConfigurationPerProfile',
  Message = 'message',
  ValidEffectiveness = 'validEffectiveness',
  InvalidEffectiveness = 'invalidEffectiveness',
  PendingEffectiveness = 'pendingEffectiveness',
  ActivityTypeId = 'activityTypeId',
  ActivitySubtypeId = 'activitySubtypeId',
  Tags = 'tags',
  CampaignId = 'campaignId',
  Priority = 'priority',
  Survey = 'survey',
  VisionRuleId = 'visionRuleId',
  Category = 'category',
  RequiredDuration = 'requiredDuration',
  ActivityTemplateGroupId = 'activityTemplateGroupId',
  ModelId = 'modelId',
  AudienceType = 'audienceType',
  TemplateTitle = 'TemplateTitle',
  DurationId = 'DurationId',
  Duration = 'Duration',
  Frequency = 'Frequency',
}

export enum DurationId {
  Fast = 'FAST',
  Medium = 'MEDIUM',
  Long = 'LONG',
  VeryLong = 'VERY_LONG',
  VeryFast = 'VERY_FAST',
  Empty = '',
}

export enum FormTypeId {
  Create = 'CREATE',
  Update = 'UPDATE',
  Duplicate = 'DUPLICATE',
}

export enum FrequencyId {
  EveryVisit = 'EVERY_VISIT',
  Monthly = 'MONTHLY',
  Effective = 'EVERY_VISIT_UNTIL_COMPLETED_OR_EFFECTIVE',
  Empty = '',
}

export enum SurveyQuestionResponseId {
  SingleSelect = 'SINGLESELECTION',
  FreeText = 'FREETEXT',
  Conditional = 'CONDITIONAL',
  Numeric = 'NUMERIC',
  MultipleSelect = 'MULTIPLESELECTION',
}

export enum OperationFormField {
  Condition = 'condition',
  Feature = 'feature',
  Operator = 'operator',
  Value = 'value',
  MinValue = 'minimumValue',
  MaxValue = 'maximumValue',
  Period = 'period',
  RequiredPeriod = 'requiredPeriod',
  Skus = 'skus',
  MetadataIds = 'metadataIds',
  FeatureType = 'featureType',
  VendorId = 'vendorId',
  AudienceId = 'audienceId',
  AudienceType = 'audienceType',
}

export enum StaticAudienceFormField {
  Audiences = 'audiences',
}

export enum AudienceTypes {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC',
}

export interface StaticAudience {
  vendorId: string;
  audienceId: string;
}
export interface ConfigActivityStaticAudienceTemplate {
  audienceType: AudienceTypes;
  audiences: StaticAudience[];
}

export enum SubTaskConditionalOptionFormField {
  Id = 'id',
  Position = 'position',
}

export enum SubTaskOptionFormField {
  Id = 'id',
  Value = 'value',
  Jump = 'jump',
}

export enum SurveyQuestionaryError {
  HasErrorQuantity = 'field must have 2 items',
  HasDuplicatedFields = 'duplicated fields are not allowed',
}

export enum SurveyQuestionaryFormField {
  Id = 'id',
  Title = 'title',
  IsRequired = 'isRequired',
  ResponseType = 'responseType',
  Options = 'options',
}

export enum TaskFormFieldSurvey {
  Survey = 'survey',
}

export enum SearchParamNameTask {
  Profile = 'profile',
  Mode = 'mode',
  Page = 'page',
  Id = 'id',
  TaskGroupId = 'taskGroupId',
}

export interface OnSuccessPriorization {
  activityId: string;
  oldPriority: number;
  newPriority: number;
  method: TriggerType;
}

export enum TriggerType {
  DragDrop = 'DRAG_DROP',
  Typing = 'TYPING',
  Null = 'null',
}

export enum TaskTab {
  Submit = 'submit',
  Priority = 'priority',
  Null = 'null',
}

export interface ConfigTaskLocalModel {
  active: boolean;
  country: string;
  endDate: string;
  id: string;
  modelId: string;
  startDate: string;
  vendorGroupId: string;
}

export interface Category {
  id: string;
  title: string;
}

export interface UpdateAndSyncTaskPriority {
  templateId: string;
  currentPriority: number | null;
  newPriority: number;
  profile?: string;
  profileId: string;
  activityTemplateGroupId: string;
}

export enum SearchParamName {
  Page = 'page',
  Size = 'size',
}

export interface VisionJobId {
  configId: string;
  description: string;
}

export enum Period {
  L15d = 'l15d',
  L30d = 'l30d',
  L60d = 'l60d',
  Lmth = 'lmth',
  Tmth = 'tmth',
  None = 'none',
}

export interface GetVisionJobId {
  visionJobId: string;
  signal?: AbortSignal;
}

export enum TaskFilterLabelId {
  NonSponsored = 'formField.label.task.list.selectTaskType.nonSponsored',
  Sponsored = 'formField.label.task.list.selectTaskType.sponsored',
}

export interface TaskFilterOption {
  id: TaskFilterType;
  labelId: TaskFilterLabelId;
}

export enum TaskFilterType {
  NonSponsored = 'non-sponsored',
  Sponsored = 'sponsored',
}
