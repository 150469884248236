/* eslint-disable prefer-destructuring */
import { useUserMetadata } from 'admin-portal-shared-services';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useGetGroups from '../../../api/services/groups/hooks/useGetGroups';
import { EMPTY_VALUE } from '../../../consts/user';
import { GetGroupDetails } from '../../../models/group';
import { Country, CountryWithOrganizations, GroupData, TGroupDataContext } from './useGroupData.d';

const initialUserGroupData: GroupData = {
  vendorGroupId: '',
  organization: { name: '', id: '', childGroups: [] },
  seller: { name: '', id: '' },
  vendors: [],
  country: '',
  error: null,
  userId: '',
};

export const GroupDataContext = createContext<TGroupDataContext>({
  groupData: initialUserGroupData,
  handleChangeUserGroupInfo: (groupInfo: Partial<GroupData>) => ({ groupInfo }),
  groups: [],
  countriesList: [{ label: '', value: '', organizations: [] }],
  countrySelected: { label: '', value: '', organizations: [] },
  handleChangeCountry: (country: Country) => country,
} as TGroupDataContext);

export const GroupDataProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [groupData, setGroupData] = useState<GroupData>(initialUserGroupData);

  const [isLoading, setIsLoading] = useState<boolean>(!!groupData.vendorGroupId);
  const { groups: totalGroups, isLoading: isGroupLoading } = useGetGroups();
  const { data: metadata, isLoading: isLoadingMetadata } = useUserMetadata();

  const countriesList: CountryWithOrganizations[] = useMemo(() => {
    if (!totalGroups) return [];

    function mapToCountryWithOrganization(
      group: GetGroupDetails,
      organizationsFromCountryUsedBefore?: GetGroupDetails[]
    ): CountryWithOrganizations {
      const isRoot = group.parentGroupId === 'root';
      const isRootGroupWithoutChildren =
        isRoot && (!group.childGroups || group.childGroups.length === 0);

      const previousOrganizations = organizationsFromCountryUsedBefore || [];

      const filteredChildGroups = !isRootGroupWithoutChildren
        ? group.childGroups.filter((g) => g.enabled)
        : [];

      const currentOrganizations = isRootGroupWithoutChildren
        ? []
        : isRoot
        ? group.childGroups
        : [
            {
              ...group,
              childGroups: filteredChildGroups,
              childGroupIds: filteredChildGroups.map((g) => g.id),
            },
          ];

      const organizations = [...previousOrganizations, ...currentOrganizations];

      return {
        label: group.country,
        value: group.country,
        organizations: organizations,
      };
    }

    const countries = totalGroups.content.reduce((object, group) => {
      return {
        ...object,
        ...(group.enabled
          ? {
              [group.country]: mapToCountryWithOrganization(
                group,
                object[group.country]?.organizations
              ),
            }
          : {}),
      };
    }, {} as { [country: string]: CountryWithOrganizations });

    return Object.keys(countries).map((country) => ({ ...countries[country] }));
  }, [totalGroups]);

  const [countrySelected, setCountrySelected] = useState<CountryWithOrganizations>();

  const groupsWithoutRoot: GetGroupDetails[] = useMemo(() => {
    if (!countrySelected) return [];
    return countrySelected.organizations;
  }, [countrySelected]);

  const updateGroupData = (prev: GroupData, groupInfo: Partial<GroupData>, userId: string) => {
    const { country, error, organization, seller, type, vendorGroupId, vendors } = groupInfo;
    const groupInfoCountry = country || seller?.country || organization?.country;
    const groupInfoId = vendorGroupId || seller?.id || organization?.id;
    const groupIdVendors = vendors || seller?.vendors || organization?.vendors;

    return {
      vendorGroupId: groupInfoId ?? prev.vendorGroupId,
      organization: organization ?? prev.organization,
      seller: seller ?? prev.seller,
      country: groupInfoCountry ?? prev.country,
      vendors: groupIdVendors ?? prev.vendors,
      error: prev.vendorGroupId !== groupInfoId ? '' : error ?? prev.error,
      type: type ?? prev.type,
      userId: userId ?? prev.userId,
    };
  };

  const handleChangeUserGroupInfo = useCallback(
    (groupInfo: Partial<GroupData>) => {
      setGroupData((prev) => {
        const newGroupData = updateGroupData(prev, groupInfo, metadata?.userId);
        localStorage.setItem('group-info', JSON.stringify(newGroupData));
        return newGroupData;
      });
    },
    [metadata?.userId]
  );

  const handleSelectNode = useCallback(
    (nodeId: string) => {
      const userNode =
        groupsWithoutRoot.find((group) => group.id === nodeId) ?? groupsWithoutRoot[0];
      handleChangeUserGroupInfo({ organization: userNode });
    },
    [groupsWithoutRoot, handleChangeUserGroupInfo]
  );

  const handleSelectFirstLeafFromNode = useCallback(() => {
    const firstChildrenFromNode = groupData.organization.childGroups?.[0] ?? EMPTY_VALUE;
    handleChangeUserGroupInfo({
      organization: groupData.organization,
      seller: firstChildrenFromNode,
    });
  }, [groupData, handleChangeUserGroupInfo]);

  const findChildGroup = (childGroups, groupInfo) => {
    if (!!groupInfo && !!childGroups && Array.isArray(childGroups) && childGroups?.length > 0) {
      return childGroups?.filter((childGroup) => childGroup.id === groupInfo.id)[0];
    } else if (!!childGroups && Array.isArray(childGroups) && childGroups?.length > 0) {
      return childGroups[0];
    } else {
      return EMPTY_VALUE;
    }
  };

  useEffect(() => {
    const groupInfoLocalStorage: GroupData = JSON.parse(localStorage.getItem('group-info'));
    if (!countrySelected && groupInfoLocalStorage) {
      const country = countriesList.find((c) => groupInfoLocalStorage.country === c.value);
      setCountrySelected(country ?? countriesList[0]);
      return;
    } else {
      if (groupInfoLocalStorage && groupInfoLocalStorage.userId === metadata?.userId) {
        const groups = countriesList
          .map((group) => group.organizations)
          .reduce((prev, actual) => [...prev, ...actual], []);

        const organization = findChildGroup(groups, groupInfoLocalStorage?.organization);
        const seller = findChildGroup(organization?.childGroups, groupInfoLocalStorage?.seller);
        const notHaveOrganizationOrSeller = Boolean(!organization && !seller.id);

        const getOrganization = () => {
          if (notHaveOrganizationOrSeller) {
            return groups.length > 0 ? groups[0] : null;
          }
          return organization;
        };
        const getSeller = () => {
          if (
            notHaveOrganizationOrSeller &&
            groups.length > 0 &&
            groups[0].childGroups.length > 0
          ) {
            return groups[0].childGroups[0];
          }
          return seller;
        };

        const groupInfo = groupInfoLocalStorage
          ? {
              ...(notHaveOrganizationOrSeller ? {} : groupInfoLocalStorage),
              organization: getOrganization(),
              seller: getSeller(),
            }
          : {
              ...initialUserGroupData,
              organization: getOrganization(),
              seller: getSeller(),
            };

        handleChangeUserGroupInfo(groupInfo);
      }
    }
  }, [
    totalGroups?.content,
    countriesList,
    countrySelected,
    groupsWithoutRoot,
    handleChangeUserGroupInfo,
    metadata?.userId,
  ]);

  const getGroupDataFromLocalStorage = useCallback(() => {
    const getLocalStorageGroupInfo = localStorage.getItem('group-info');

    if (getLocalStorageGroupInfo === null) {
      localStorage.setItem(
        'group-info',
        JSON.stringify({ ...initialUserGroupData, userId: metadata?.userId })
      );
    } else {
      const parsed: GroupData = JSON.parse(getLocalStorageGroupInfo);
      const userId = parsed.userId;
      if (metadata?.userId === userId) {
        setGroupData(parsed);
      }
    }
  }, [metadata?.userId]);

  useEffect(() => {
    const isReadyMetaData = !isLoadingMetadata && metadata.nodes['force'].length;
    const isReadyGroupData = !isGroupLoading && totalGroups?.content;
    const groupInfoLocalStorage = localStorage.getItem('group-info');

    const hasInfoInLocalStorage =
      groupInfoLocalStorage !== null &&
      metadata?.userId === JSON.parse(groupInfoLocalStorage).userId &&
      JSON.parse(groupInfoLocalStorage).country;
    const isFirstTime = !hasInfoInLocalStorage;

    if (isFirstTime) {
      getGroupDataFromLocalStorage();
      if (isReadyMetaData && isReadyGroupData) {
        setCountrySelected(countriesList[0]);
        handleSelectNode(metadata.nodes['force'][0]);
      }
    }
  }, [
    countriesList,
    isGroupLoading,
    groupData.vendorGroupId,
    totalGroups?.content,
    metadata,
    isLoadingMetadata,
    handleSelectNode,
    getGroupDataFromLocalStorage,
  ]);

  // TO DO: Add logic to reload the optmizely config without force this re-render
  useEffect(() => {
    if (groupData.vendorGroupId) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [groupData.vendorGroupId]);

  const contextValue = useMemo(() => {
    return {
      groupData,
      groups: groupsWithoutRoot,
      handleChangeUserGroupInfo,
      handleSelectFirstLeafFromNode,
      countriesList,
      countrySelected,
      handleChangeCountry: setCountrySelected,
    };
  }, [
    groupData,
    groupsWithoutRoot,
    handleChangeUserGroupInfo,
    handleSelectFirstLeafFromNode,
    countriesList,
    countrySelected,
  ]);

  if (isLoading || isLoadingMetadata) {
    return <div>Loading...</div>;
  }

  return <GroupDataContext.Provider value={contextValue}>{children}</GroupDataContext.Provider>;
};

const useGroupData: () => TGroupDataContext = () => useContext(GroupDataContext);

export default useGroupData;
