import useGroupData from '../../../hooks/useContext/useGroupData/useGroupData';
import { useCallback, useEffect, useMemo } from 'react';
import { GetGroupDetails } from '../../../models/group';
import { EMPTY_VALUE } from '../../../consts/user';
import { SearchParamNameTask } from '../../../models/activity';
import { useSearchParams } from 'react-router-dom';
import { CountryWithOrganizations } from '../../../hooks/useContext/useGroupData/useGroupData.d';

export interface UseConfigureOperationModalProps {
  countrySelected?: CountryWithOrganizations;
  selectedOrganization?: GetGroupDetails;
  selectedSeller?: GetGroupDetails;
  onClose: () => void;
}

export const useConfigureOperationModal = (options: UseConfigureOperationModalProps) => {
  const {
    groupData,
    handleSelectFirstLeafFromNode,
    countriesList,
    handleChangeUserGroupInfo,
    handleChangeCountry,
  } = useGroupData();
  const [searchParams, setSearch] = useSearchParams();
  const setSearchParams = useCallback(
    (params: Parameters<typeof setSearch>[0]) => setSearch(params, { replace: true }),
    [setSearch]
  );
  const queryTaskGroupId = searchParams.get(SearchParamNameTask.TaskGroupId);

  const { organization } = groupData;

  const organizations = options.countrySelected?.organizations;
  const hasNoOperationsAvailable = !organizations?.length;
  const isCountrySelectorDisabled = countriesList.length === 1;

  const isSaveButtonDisable = useMemo(() => {
    const isCountrySelected = !!options.countrySelected;
    const isOrganizationSelected = !!options.selectedOrganization?.id;
    const hasChildGroups = (options.selectedOrganization?.childGroups?.length ?? 0) > 0;
    const isSellerSelected = !!options.selectedSeller?.id;

    return (
      !isCountrySelected ||
      !isOrganizationSelected ||
      (hasChildGroups && !isSellerSelected) ||
      hasNoOperationsAvailable
    );
  }, [
    options.countrySelected,
    options.selectedOrganization,
    options.selectedSeller?.id,
    hasNoOperationsAvailable,
  ]);

  useEffect(() => {
    if (!organization) return;
    if (!options.selectedSeller) return;

    const hasSellerAvailable = !!organization.childGroups?.length;
    const hasNoSellerSelected = !options.selectedSeller?.id;
    if (hasSellerAvailable && hasNoSellerSelected) handleSelectFirstLeafFromNode();
  }, [handleSelectFirstLeafFromNode, organization, options.selectedSeller]);

  const sellers: GetGroupDetails[] = useMemo(() => {
    if (!options.selectedOrganization || !options.selectedOrganization?.childGroups) return [];
    else return [...options.selectedOrganization.childGroups];
  }, [options.selectedOrganization]);

  const onConfirmModal = useCallback(() => {
    handleChangeUserGroupInfo({
      organization: options.selectedOrganization,
      seller: options.selectedSeller ?? EMPTY_VALUE,
    });
    handleChangeCountry(options.countrySelected);
    if (queryTaskGroupId) {
      searchParams.delete(SearchParamNameTask.TaskGroupId);
      setSearchParams(searchParams);
    }
    options.onClose();
  }, [
    handleChangeCountry,
    handleChangeUserGroupInfo,
    options,
    queryTaskGroupId,
    searchParams,
    setSearchParams,
  ]);

  return {
    organizations,
    hasNoOperationsAvailable,
    isSaveButtonDisable,
    isCountrySelectorDisabled,
    sellers,
    onConfirmModal,
  };
};
