import { styled } from '@hexa-ui/theme';

const WrapperDialogButtons = styled('div', {
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
});

const BodyContainer = styled('div', {
  width: '644px',
});

const InputContainer = styled('div', {
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
});

const Styled = {
  WrapperDialogButtons,
  BodyContainer,
  InputContainer,
};

export default Styled;
